.blog-container-info{
    height: 600px !important;
}

.blog-img-info{
    height: 60% !important;
}

.blog-data-info{
    height: 40% !important;
}